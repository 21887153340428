import React from "react";
import styled from "styled-components";
import { media } from "../../style/media";

const StyledButton = styled.a`
  background-color: ${(props) => props.colour || "#FFF"};
  font-size: 1rem;
  padding: ${(props) => props.padding || ".8rem 2rem"};
  color: ${(props) => props.border || "#FFF"};
  text-decoration: none;
  border-radius: 2rem;
  font-family: Graphik-500;
  z-index: 3;
  width: ${(props) => props.width};
  min-width: 80px;
  text-align: center;
  border: ${(props) => `${props.border} solid 2px` || "#721ED8 solid 2px"};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.hover || "white"};
    color: ${(props) => props.hoverText || "#721ED8"};
  }

  @media ${media.tablet} {
    margin-top: 1rem;
  }
`;

//Button component with a text prop that allows for reusability across the application
//The component is a styled anchor tag
const Button = ({
  onClick,
  text,
  type,
  width,
  padding,
  colour,
  border,
  mb,
  href,
  hover,
  hoverText,
}) => {
  //The Text prop renders what will be seen in the button
  //To prop will render the link, has to include http
  //The type prop will determine the action
  //The width prop is for custom widths

  return (
    <>
      <StyledButton
        type={type ? type : null}
        width={width}
        padding={padding}
        colour={colour}
        href={href}
        hoverText={hoverText}
        border={border}
        hover={hover}
        mb={mb}
        target="_blank"
        onClick={onClick}
      >
        {text}
      </StyledButton>
    </>
  );
};

export default Button;
