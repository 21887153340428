import React from "react";
import NavBar from "../components/SharedComponents/nav/NavBar";
import {
  DLoadSubContainer,
  DownloadContentContainer,
  DownloadContainer,
  DownloadImage,
  DownloadSubText,
} from "../components/Download/styles/Download.styled";
import Footer from "../components/SharedComponents/Footer/Footer";
import GradientHeading from "../components/SharedComponents/GradientHeading/GradientHeading";
import Button from "../components/SharedComponents/Button";

const Download = () => {
  // Function to determine download path based on user agent
  const download = () => {
    window.open(
      "https://avai.gitbook.io/spingle-knowledge-base/using-the-premiere-pro-extension",
      "_blank",
    );

    const userAgent = navigator.userAgent.toLowerCase();
    let downloadPath = "";

    const isMac = userAgent.includes("macintosh");
    const macVersion = userAgent.match(/mac os x (\d+)[._](\d+)/);
    const isNewMacOS =
      macVersion &&
      parseInt(macVersion[1], 10) >= 10 &&
      parseInt(macVersion[2], 10) >= 15;
    const isAppleSilicon =
      userAgent.includes("apple m1") || userAgent.includes("apple silicon");

    if (isMac && (isNewMacOS || isAppleSilicon)) {
      downloadPath =
        "https://github.com/AudioVideoAI/SpingleReleases/releases/download/v1.1.0/Spingle_AI_Mac_Installer_arm64.dmg"; // Path to Mac ARM (Silicon)
    } else if (isMac && userAgent.includes("intel")) {
      downloadPath =
        "https://github.com/AudioVideoAI/SpingleReleases/releases/download/v1.1.0/Spingle_AI_Mac_Installer_x86.dmg"; // Path to Mac Intel
    } else if (userAgent.includes("win")) {
      downloadPath =
        "https://github.com/AudioVideoAI/SpingleReleases/releases/download/v1.1.0/Spingle_AI_Windows_Installer.exe"; // Path to Windows
    } else {
      // Alert if not Mac or Windows or a mobile device
      alert(
        "This app is only available for Mac or Windows desktop/laptop devices.",
      );
      return;
    }

    console.log(downloadPath); // For testing purposes
    window.location.href = downloadPath; // Trigger the download
  };
  return (
    <div>
      <NavBar showLink={false} />
      <DownloadContainer>
        <DownloadContentContainer>
          <DLoadSubContainer>
            <GradientHeading
              mb={0.5}
              width={"70%"}
              text={"Download Spingle to Get Started"}
            />
            <DownloadSubText>
              We're excited to partner with you on your creative journey,
              helping you turn your footage into compelling stories. We want to
              help you spend more time creating the stories that matter.
            </DownloadSubText>
            <Button
              text={"Download Now"}
              colour={"#0F0F0F"}
              padding={"1rem 2rem"}
              onClick={download}
              hover={"#721ED8"}
              hoverText={"white"}
            />
          </DLoadSubContainer>
          <DLoadSubContainer>
            <DownloadImage src="https://res.cloudinary.com/dd0g0joex/image/upload/v1726364186/aesthetic-retro-8bit-landscape-35bdqga3u3xw4hu7_buimgt.webp" />
          </DLoadSubContainer>
        </DownloadContentContainer>
      </DownloadContainer>
      <Footer />
    </div>
  );
};

export default Download;
