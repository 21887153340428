import styled from "styled-components";
import { media } from "../../../style/media";
import { StyledParagraph } from "../../App/CallToAction/styles/CTA.styled";

const DownloadContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
  background: linear-gradient(
    180deg,
    rgba(40, 12, 66, 0.8) 0%,
    rgba(73, 8, 133, 0.8) 54.69%,
    rgba(29, 3, 33, 0.8) 100%
  );
  backdrop-filter: blur(25px);
  overflow: hidden;
  align-items: center;
  width: 100%;
  margin: 0 auto;

  @media ${media.tablet} {
    flex-direction: column;
    justify-content: center;
    height: fit-content;
  }
`;
const DownloadSubText = styled(StyledParagraph)`
  color: #2e2e2e;
  width: 90%;
  margin-bottom: 3rem;
  font-family: Graphik-400;
  line-height: 1.3rem;
  margin-right: 0;

  @media ${media.laptopLarge} {
    font-size: 1.1rem;
  }

  @media ${media.laptop} {
    font-size: 0.9rem;
    width: 100%;
    line-height: 1.1rem;
    margin-bottom: 2rem;
  }

  @media ${media.tablet} {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 0.8rem;
  }

  @media ${media.mobile} {
    margin-bottom: 1rem;
  }
`;

const DownloadContentContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  background: white;
  backdrop-filter: blur(25px);
  overflow: hidden;
  align-items: center;
  width: 100%;
  padding: 4rem;
  max-width: 1440px;
  margin: 0 auto;
  grid-gap: 4rem;

  @media ${media.laptop} {
    padding: 3rem;
  }

  @media ${media.tablet} {
    flex-direction: column;
    padding: 2rem 0rem;
    grid-gap: 2rem;
  }
`;

const DownloadImage = styled.img`
  width: 100%;

  @media ${media.tablet} {
    width: 80%;
    margin-bottom: 1rem;
  }

  @media ${media.mobile} {
    width: 100%;
  }
`;

const DLoadSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  flex: 1;

  @media ${media.tablet} {
    align-items: center;
    text-align: center;
  }
`;

const DownloadHeading = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: #000;
  text-align: left;

  @media ${media.tablet} {
    font-size: 1.75rem;
  }

  @media ${media.mobile} {
    font-size: 1.5rem;
    text-align: center;
  }
`;

export {
  DownloadContainer,
  DLoadSubContainer,
  DownloadHeading,
  DownloadContentContainer,
  DownloadImage,
  DownloadSubText,
};
