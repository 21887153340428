import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ReactDOM from "react-dom/client";
import App from "./App";
import Error from "./pages/Error";
import Success from "./pages/Success";
import Download from "./pages/Download";
import ThankYou from "./pages/ThankYou";
//front end routing for Cleancut, this will handle all the page renders and Navigation links
//Done using v6 object notation
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
  },
  {
    path: "/success",
    element: <Success />,
    errorElement: <Error />,
  },
  {
    path: "/download",
    element: <Download />,
    errorElement: <Error />,
  },
  {
    path: "/thankyou",
    element: <ThankYou />,
    errorElement: <Error />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
