import React from "react";
import { HeadingText } from "./style/GradientHeading.style";

const GradientHeading = ({ text, mt, mb, ta, width }) => {
  return (
    <HeadingText mt={mt} mb={mb} ta={ta} style={{ width: width }}>
      {text}
    </HeadingText>
  );
};

export default GradientHeading;

